export function useAssecoFavicon() {
  const config = useRuntimeConfig();
  useHead({
    link: [
      {
        rel: "icon",
        type: "image/svg+xml",
        href: `${config.app.baseURL}/assecods_favicon.svg`,
      },
    ],
  });
}
